import React from 'react';

import { MenuItem, menuItems } from '../../../constants/menuItems';
import { MenuLink, MenuWrapper } from './style';

const MenuItemWrapper: React.FC<MenuItem> = function MenuItemWrapper({ url, title }) {
    return (
        <li>
            <MenuLink to={url} end>
                {title}
            </MenuLink>
        </li>
    );
};

export const Menu: React.FC = function Menu() {
    return (
        <nav>
            <MenuWrapper>
                {menuItems.map((m) => (
                    <MenuItemWrapper key={m.url} url={m.url} title={m.title} />
                ))}
            </MenuWrapper>
        </nav>
    );
};
