import React, { ReactNode } from 'react';

import DocumentImage from './assets/document.png';
import { DocumentsContainer, DocumentStyle } from './style';

interface IDocument {
    name: string;
    download_url: string;
}

export function Document(props: IDocument) {
    return (
        <DocumentStyle>
            <a href={props.download_url} target="_blank" rel="noreferrer">
                <img src={DocumentImage} alt={`Документ ${props.name}`} />
                <p>{props.name}</p>
            </a>
        </DocumentStyle>
    );
}

interface IDocumentsProps {
    children: ReactNode;
}

export const Documents: React.FC<IDocumentsProps> = function Documents({ children }) {
    return <DocumentsContainer>{children}</DocumentsContainer>;
};
