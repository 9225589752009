import { useCallback, useEffect, useId, useLayoutEffect, useRef, useState } from 'react';
import axios from 'axios';

const API_BASE = '';
const API_PREFIX = 'api';
const API_VERSION = 'v1';

const axiosApiInstance = axios.create({
    baseURL: `${API_BASE}/${API_PREFIX}/${API_VERSION}`,
});

export enum ApiResponseStatus {
    SUCCESS = 0,
    ERROR = 10,
}

interface ISuccessfulApiResponse<DataType> {
    status: ApiResponseStatus.SUCCESS;
    data: DataType;
}

interface IFailedApiResponse {
    status: ApiResponseStatus.ERROR;
    statusMsg: string;
}

type IApiResponse<DataType> = ISuccessfulApiResponse<DataType> | IFailedApiResponse;

async function apiFetcher(url: string) {
    const response = await axiosApiInstance.get<IApiResponse<any>>(url);

    if (response.data.status === ApiResponseStatus.ERROR) {
        throw new Error(response.data.statusMsg);
    }

    return response.data.data;
}

export interface ICourse {
    id: number;
    coverImg: string;
    colorBlock: string;
    title: string;
}

export interface IAttachment {
    title: string;
    fileName: string;
}

export interface ICourseDetailed {
    title: string;
    text: string;
    attachedFiles: IAttachment[];
}

export interface ITeamMember {
    firstName: string;
    lastName: string;
    position: string;
    photo: string;
}

const mutators: { [key: string]: { (): void } } = {};

/**
 * Инвалидирует все ответы от API. Используется, например, при смене статуса авторизации.
 */
export const mutate = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [_key, mutator] of Object.entries(mutators)) {
        mutator();
    }
};

interface ISomethingLikeSWRReturn<T> {
    readonly data: T;
    isLoading: boolean;
    mutate: (data?: T) => void;
}

export function useSomethingLikeSWR<T>(
    params: string,
    fetcher: (url: string) => Promise<T>
): ISomethingLikeSWRReturn<T>;
export function useSomethingLikeSWR<T, U = any, P extends any[] = any[]>(
    params: Readonly<P>,
    fetcher: (...args: Readonly<P>) => Promise<T>
): ISomethingLikeSWRReturn<T>;
export function useSomethingLikeSWR<T, U = any, P extends any[] = any[]>(
    params: string | Readonly<P>,
    fetcher: (...args: any) => Promise<T>
) {
    const [response, setResponse] = useState<T>();
    const [isLoading, setIsLoading] = useState(true);
    const fetcherRef = useRef(fetcher);

    const fetchData = useCallback((_params: string | Readonly<P>) => {
        const fetchFunction =
            typeof _params === 'string' ? fetcherRef.current(_params) : fetcherRef.current(..._params);

        fetchFunction.then((_response) => {
            setResponse(_response);
            setIsLoading(false);
        });
    }, []);

    const id = useId();

    const internalMutate = useCallback(
        (data?: T) => {
            if (data) {
                setResponse(data);
            } else {
                fetchData(params);
            }
        },
        [fetchData, params]
    );

    useLayoutEffect(() => {
        fetcherRef.current = fetcher;
    });

    useEffect(() => {
        fetchData(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, JSON.stringify(params)]);

    useEffect(() => {
        mutators[id] = internalMutate;

        return () => {
            delete mutators[id];
        };
    }, [internalMutate, id]);

    if (isLoading)
        return {
            get data() {
                return undefined;
            },
            isLoading: true,
            mutate: internalMutate,
        };

    return {
        get data() {
            return response as T;
        },
        isLoading: false,
        mutate: internalMutate,
    };
}

export const useCourses = () => useSomethingLikeSWR<ICourse[]>('/studyProgram', apiFetcher);
export const useTeam = () => useSomethingLikeSWR<ITeamMember[]>('/employee', apiFetcher);
export const useCourse = (id: number) => useSomethingLikeSWR<ICourseDetailed>(`/studyProgram/${id}`, apiFetcher);

export const getFileUrl = (filename: string) => `${API_BASE}/files/${filename}`;
