import { ROUTES } from './routes';

export interface MenuItem {
    url: string;
    title: string;
}

export const menuItems: MenuItem[] = [
    {
        url: ROUTES.MAIN,
        title: 'Главная',
    },
    {
        url: ROUTES.MAIN + ROUTES.INFO,
        title: 'Основные сведения',
    },
    {
        url: ROUTES.MAIN + ROUTES.STRUCTURE,
        title: 'Структура и органы управления',
    },
    {
        url: ROUTES.MAIN + ROUTES.DOCUMENTS,
        title: 'Документы',
    },
    {
        url: ROUTES.MAIN + ROUTES.EDUCATION,
        title: 'Образование',
    },
    {
        url: ROUTES.MAIN + ROUTES.STANDARTS,
        title: 'Образовательные стандарты',
    },
    {
        url: ROUTES.MAIN + ROUTES.STAFF,
        title: 'Руководство. Педагогический (научно-педагогический) состав',
    },
    {
        url: ROUTES.MAIN + ROUTES.MAT_SUPPORT,
        title: 'Материально-техническое обеспечение и оснащенность образовательного процесса',
    },
    {
        url: ROUTES.MAIN + ROUTES.SCHOLARSHIP,
        title: 'Стипендии и меры поддержки обучающихся',
    },
    {
        url: ROUTES.MAIN + ROUTES.SERVICES,
        title: 'Платные образовательные услуги',
    },
    {
        url: ROUTES.MAIN + ROUTES.FINANCIAL,
        title: 'Финансово-хозяйственная деятельность',
    },
    {
        url: ROUTES.MAIN + ROUTES.VACANT,
        title: 'Вакантные места для приёма (перевода) обучающихся',
    },
    {
        url: ROUTES.MAIN + ROUTES.ACCESSIBLE,
        title: 'Доступная среда',
    },
    {
        url: ROUTES.MAIN + ROUTES.INTERNATIONAL,
        title: 'Международное сотрудничество',
    },
];
