import React from 'react';

import { Document, Documents } from '../../components/widgets/Documents';
import { TextBlock } from '../../components/widgets/TextBlock';

export const FinancialPage = function FinancialPage() {
    // нет документов
    // Отчет о поступлении и расходовании фин. средств (за 2022 год)
    // План финансово-хозяйственной деятельности ООО «С8 Академия» на 2023 год
    const docs: any[] = [];

    return (
        <div>
            <TextBlock title="Финансово-хозяйственная деятельность">
                <p>
                    Образовательной деятельности, финансовое обеспечение которой осуществляется за счет бюджетных
                    ассигнований федерального бюджета, бюджетов субъектов Российской Федерации, местных бюджетов не
                    имеется.
                </p>
            </TextBlock>

            <Documents>
                {docs.map((item) => {
                    const name = item.name ? item.name : item.pdf?.split('.pdf')[0];

                    return <Document name={name} download_url={`/pdf/${item.pdf}`} />;
                })}
            </Documents>
        </div>
    );
};
