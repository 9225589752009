import React from 'react';

import { TextBlock } from '../../components/widgets/TextBlock';

export const StaffPage = function StaffPage() {
    return (
        <TextBlock title="Руководство. Педагогический (научно-педагогический) состав">
            <p>
                <h3>Директор ООО &laquo;С8&nbsp;Академия&raquo;&nbsp;&mdash; Васильев Артем Юрьевич.</h3>
                <b>Контактный телефон</b> +7-495-204-85-00
                <br />
                <b>Электронная почта</b> s8academy@s8.capital
            </p>
            <h2>Персональный состав педагогических работников:</h2>
            <ul>
                <li>
                    <h3>Пархоменко Сергей Григорьевич</h3>
                    <table>
                        <tr>
                            <td>Должность</td>
                            <td>Руководитель направления корпоративного обучения</td>
                        </tr>
                        <tr>
                            <td>Наименование учебного заведения, уровень образования, специальность по&nbsp;диплому</td>
                            <td>
                                Государственное бюджетное образовательное учреждение высшего профессионального
                                образования Московской области &laquo;Финансово-технологическая академия&raquo; г.
                                Королёв. Экономист&nbsp;&mdash; менеджер.
                            </td>
                        </tr>
                        <tr>
                            <td>Ученая степень, ученое звание (при наличии)</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>Общий стаж работы</td>
                            <td>15 лет</td>
                        </tr>
                        <tr>
                            <td>Стаж работы по&nbsp;специальности</td>
                            <td>11 лет</td>
                        </tr>
                        <tr>
                            <td>Преподаваемые дисциплины</td>
                            <td>
                                <ul>
                                    <li>Личная эффективность;</li>
                                    <li>Бизнес-коммуникация;</li>
                                    <li>Визуализация информации (создание презентаций);</li>
                                    <li>Наставничество</li>
                                    <li>Тренинг для тренеров;</li>
                                    <li>Фасилитационные сессии;</li>
                                    <li>Продажи (B2C / B2B);</li>
                                    <li>Клиентоориентированность;</li>
                                    <li>Проведение бизнес-игр;</li>
                                    <li>
                                        Управление персоналом (команда, ОС, DISC, мотивация, проведение собеседования,
                                        адаптация, постановка задач, контроль).
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Данные о&nbsp;повышении квалификации/или профессиональной переподготовки</td>
                            <td>
                                Автономная некоммерческая организация дополнительного профессионального образования
                                &laquo;ЦНТИ &laquo;Прогресс&raquo;, &laquo;Организация и&nbsp;проведение дополнительного
                                профессионального обучения&raquo;.
                                <br />
                                Серия и&nbsp;номер
                                <br />
                                7805 00252756
                                <br />
                                16.11.2022
                            </td>
                        </tr>
                    </table>
                </li>
            </ul>
        </TextBlock>
    );
};
