import styled from 'styled-components';

export const OrganizationStructureStyle = styled.div`
    position: relative;
    overflow: hidden;

    .sub_level {
        margin-top: 24px;
    }
`;

const baseLeftPadding = '17px';
const verticalLineLeftPadding = '38px';

export const StructureItem = styled.div<{ level: number }>`
    font-weight: 700;
    font-size: 1.3rem;
    border-radius: 100px;
    border: 1px solid var(--brand-color-1);
    text-align: center;
    padding: 18px 50px;
    display: table;
    position: relative;

    & + & {
        margin-top: 24px;
    }

    ${(props) =>
        props.level &&
        `
        margin-left: calc(${verticalLineLeftPadding} + ${baseLeftPadding} * ${props.level});

        &:before{
            width: calc(${props.level}*${baseLeftPadding});
            left:  calc(-1*${props.level}*${baseLeftPadding});
        }
    
        &:before {
            content: "";
            border-left: 1px solid var(--brand-color-1);
            border-bottom: 1px solid var(--brand-color-1);
            z-index: -1;
            position: absolute;
            top: -100vh;
            bottom: 50%;
        }
    `}
`;
