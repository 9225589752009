import React from 'react';

import { Document, Documents } from '../../components/widgets/Documents';
import { TextBlock } from '../../components/widgets/TextBlock';

export const ServicesPage = function ServicesPage() {
    // нет документов
    // Приказ об утверждении стоимости обучения
    // Договор об оказании образовательных услуг
    // Договор оказания платных образовательных услуг
    const docs: any[] = [{ pdf: 'Положение о порядке и условиях оказания платных образовательных услуг.pdf' }];

    return (
        <div>
            <TextBlock title="Платные образовательные услуги">
                <p>
                    Вся деятельность организации осуществляется за&nbsp;счет средств, полученных по&nbsp;Договорам
                    об&nbsp;оказании платных образовательных услуг. Платные образовательные услуги оказываются
                    в&nbsp;соответствии с&nbsp;положениями Федерального закона от&nbsp;29.12.2012&nbsp;N 273-ФЗ
                    (ред.&nbsp;от&nbsp;31.07.2020) &laquo;Об&nbsp;образовании в&nbsp;Российской Федерации&raquo;.{' '}
                </p>
            </TextBlock>

            <Documents>
                {docs.map((item) => {
                    const name = item.name ? item.name : item.pdf?.split('.pdf')[0];

                    return <Document name={name} download_url={`/pdf/${item.pdf}`} />;
                })}
            </Documents>
        </div>
    );
};
