import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ROUTES } from './constants/routes';
import { ScrollToTop } from './helpers/ScrollToTop';
import { DefaultLayout } from './layouts/Default';
import { AccessiblePage } from './pages/Accessible';
import { CoursePage } from './pages/Course';
import { DocumentsPage } from './pages/Documents';
import { EducationPage } from './pages/Education';
import { FinancialPage } from './pages/Financial';
import { InfoPage } from './pages/Info';
import { InternationalPage } from './pages/International';
import { MainPage } from './pages/Main';
import { NotFoundPage } from './pages/NotFound';
import { ScholarshipPage } from './pages/Scholarship';
import { ServicesPage } from './pages/Services';
import { StaffPage } from './pages/Staff';
import { StandartsPage } from './pages/Standarts';
import { StructurePage } from './pages/Structure';
import { SupportPage } from './pages/Support';
import { VacantPage } from './pages/Vacant';
import { GlobalStyles } from './styles/global';

export function App() {
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path={ROUTES.MAIN} element={<DefaultLayout />}>
                    <Route index element={<MainPage />} />
                    <Route path={ROUTES.INFO} element={<InfoPage />} />
                    <Route path={ROUTES.STRUCTURE} element={<StructurePage />} />
                    <Route path={ROUTES.DOCUMENTS} element={<DocumentsPage />} />
                    <Route path={ROUTES.EDUCATION} element={<EducationPage />} />
                    <Route path={ROUTES.STANDARTS} element={<StandartsPage />} />
                    <Route path={ROUTES.STAFF} element={<StaffPage />} />
                    <Route path={ROUTES.MAT_SUPPORT} element={<SupportPage />} />
                    <Route path={ROUTES.SCHOLARSHIP} element={<ScholarshipPage />} />
                    <Route path={ROUTES.SERVICES} element={<ServicesPage />} />
                    <Route path={ROUTES.FINANCIAL} element={<FinancialPage />} />
                    <Route path={ROUTES.VACANT} element={<VacantPage />} />
                    <Route path={ROUTES.ACCESSIBLE} element={<AccessiblePage />} />
                    <Route path={ROUTES.INTERNATIONAL} element={<InternationalPage />} />

                    <Route path={ROUTES.PROGRAM_PAGE} element={<CoursePage />} />

                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Routes>
            <GlobalStyles />
        </>
    );
}
