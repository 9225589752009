import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const MenuWrapper = styled.ul`
    list-style: none;
    list-style-image: none;
    width: 360px;
    margin-right: 40px;
    padding: 0;

    & > li:not(:last-child) {
        margin-bottom: 16px;
    }
`;

export const MenuLink = styled(NavLink)`
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.1;
    color: var(--text-color);

    &:hover {
        text-decoration: underline var(--brand-color-1);
    }

    &[class*='active'] {
        text-decoration: underline;
    }
`;
