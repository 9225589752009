import React from 'react';

import { TextBlockStyle } from './style';

interface Props {
    children: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    title?: string;
}

export const TextBlock: React.FC<Props> = function TextBlock({ children, title }) {
    return (
        <div>
            {title && <h1>{title}</h1>}
            <TextBlockStyle>{children}</TextBlockStyle>
        </div>
    );
};
