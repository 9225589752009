import React from 'react';
import { Link } from 'react-router-dom';

import { useCourses } from '../../../api';
// import { SocialMediaBlock } from './SocialMediaBlock';
import { FooterStyle } from './style';

export function Courses() {
    const { data: courses, isLoading } = useCourses();

    if (isLoading) return <>Загрузка...</>;

    return (
        <div className="column_content">
            {courses?.map((course) => (
                <div key={course.id} className="sub_item">
                    <Link to={`/courses/${course.id}`}>{course.title}</Link>
                </div>
            ))}
        </div>
    );
}

export function InfoColumn() {
    return (
        <div>
            <div className="phone">
                <div className="number">+7 (495) 204-85-00</div>
                <div className="sub_item">Контактный телефон</div>
            </div>
            <div className="sub_item contacts">
                Россия, Москва, 1-й Красногвардейский проезд, д. 21, с. 1
                <br />
                <br />
                E-mail: s8academy@s8.capital
            </div>
            <div className="socialMedia">{/* <SocialMediaBlock /> */}</div>
            <div className="copyright">s8 | Academy, {(new Date()).getFullYear()}</div>
        </div>
    );
}

export function CoursesColumn() {
    return (
        <div>
            <div className="header">Программы</div>
            <Courses />
        </div>
    );
}

export function Footer() {
    return (
        <FooterStyle>
            <InfoColumn />
            <CoursesColumn />
        </FooterStyle>
    );
}
