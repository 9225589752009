import styled from 'styled-components';

export const FooterStyle = styled.div`
    background-color: var(--bg-alt-color);
    color: var(--text-alt-color);
    width: 100%;
    border-top: 2px solid var(--text-color);

    padding: 100px 106px;

    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    & > div + div {
        border-top: none;
        margin-top: 0;
    }

    & > div:nth-child(1) {
        max-width: 372px;
    }

    & .header {
        font-weight: 500;
        font-size: 1.2rem;
        color: var(--text-alt-color);
    }

    & .phone {
        color: var(--text-alt-color);
        font-size: 1.2em;
        margin-bottom: 32px;
    }

    & .phone .number {
        font-size: 1.2em;
    }

    & .sub_item {
        font-size: 0.95rem;
        font-weight: 300;

        a {
            text-decoration: none;
            color: var(--text-alt-color);

            &:visited {
                color: var(--text-alt-color);
            }
        }
    }

    & .contacts {
        font-weight: 400;
        font-size: 1.1rem;
        margin-bottom: 58px;
    }

    & .socialMedia {
        margin-bottom: 128px;
    }

    & .copyright {
        font-weight: 400;
        font-size: 1.1rem;
    }

    & .column_content {
        margin-top: 26px;

        div + div {
            margin-top: 16px;
        }
    }

    & .loading {
        color: var(--text-alt-color);
    }
`;
