import React from 'react';

import { CloseBtn, FontSizeBtn, Panel, SpacingBtn, SubPanel, ThemeBtn, Wrapper } from './style';

type FontSize = 16 | 24 | 32;
type Spacing = 0 | 1 | 2;
type Theme = 'white' | 'black' | 'blue' | 'beige' | 'brown';

interface Props {
    onClose: () => void;
}

export const setInitialA11y = () => {
    document.body.classList.add('contrast-theme-white');
};

export const A11yPanel: React.FC<Props> = function A11yPanel({ onClose }) {
    const [fontSize, changeFontSize] = React.useState(16);
    const [spacing, changeSpacing] = React.useState(0);
    const [theme, changeTheme] = React.useState('white');

    const changeUserFontSize = (userFontSize: FontSize) => {
        document.documentElement.style.setProperty('font-size', `${userFontSize}px`);
        changeFontSize(userFontSize);
    };

    const changeUserSpacing = (userSpacing: Spacing) => {
        document.body.classList.remove(`spacing-${spacing}`);
        document.body.classList.add(`spacing-${userSpacing}`);
        changeSpacing(userSpacing);
    };

    const changeUserTheme = (userTheme: Theme) => {
        document.body.classList.remove(`contrast-theme-${theme}`);
        document.body.classList.add(`contrast-theme-${userTheme}`);
        changeTheme(userTheme);
    };

    const closePanel = () => {
        document.documentElement.style.setProperty('font-size', '16px');
        document.body.classList.remove(`spacing-${spacing}`);
        document.body.classList.remove(`contrast-theme-${theme}`);
        onClose();
    };

    return (
        <Wrapper>
            <Panel>
                <SubPanel>
                    <span>Размер шрифта</span>
                    <FontSizeBtn type="button" onClick={() => changeUserFontSize(16)} $active={fontSize === 16}>
                        A
                    </FontSizeBtn>
                    <FontSizeBtn type="button" onClick={() => changeUserFontSize(24)} $active={fontSize === 24}>
                        A
                    </FontSizeBtn>
                    <FontSizeBtn type="button" onClick={() => changeUserFontSize(32)} $active={fontSize === 32}>
                        A
                    </FontSizeBtn>
                </SubPanel>
                <SubPanel>
                    <span>Интервал между буквами</span>
                    <SpacingBtn type="button" onClick={() => changeUserSpacing(0)} $active={spacing === 0}>
                        AБВ
                    </SpacingBtn>
                    <SpacingBtn type="button" onClick={() => changeUserSpacing(1)} $active={spacing === 1}>
                        АБВ
                    </SpacingBtn>
                    <SpacingBtn type="button" onClick={() => changeUserSpacing(2)} $active={spacing === 2}>
                        АБВ
                    </SpacingBtn>
                </SubPanel>
                <SubPanel>
                    <span>Цвет сайта</span>
                    <ThemeBtn
                        type="button"
                        className="contrast-theme-white"
                        onClick={() => changeUserTheme('white')}
                        $active={theme === 'white'}
                    >
                        A
                    </ThemeBtn>
                    <ThemeBtn
                        type="button"
                        className="contrast-theme-black"
                        onClick={() => changeUserTheme('black')}
                        $active={theme === 'black'}
                    >
                        A
                    </ThemeBtn>
                    <ThemeBtn
                        type="button"
                        className="contrast-theme-blue"
                        onClick={() => changeUserTheme('blue')}
                        $active={theme === 'blue'}
                    >
                        A
                    </ThemeBtn>
                    <ThemeBtn
                        type="button"
                        className="contrast-theme-beige"
                        onClick={() => changeUserTheme('beige')}
                        $active={theme === 'beige'}
                    >
                        A
                    </ThemeBtn>
                    <ThemeBtn
                        type="button"
                        className="contrast-theme-brown"
                        onClick={() => changeUserTheme('brown')}
                        $active={theme === 'brown'}
                    >
                        A
                    </ThemeBtn>
                </SubPanel>
                <CloseBtn type="button" onClick={() => closePanel()}>
                    Обычная версия сайта
                </CloseBtn>
            </Panel>
        </Wrapper>
    );
};
