import React from 'react';

import { Document, Documents } from '../../components/widgets/Documents';
import { TextBlock } from '../../components/widgets/TextBlock';
import { OrganizationStructure } from './widgets/OrganizationStructure';

export const StructurePage = function StructurePage() {
    // нужны новые доки
    // Положение об общем собрании работников ООО «С8 Академия»
    // Положение о Педагогическом совете в ООО «С8 Академия»
    const docs = [
        {
            name: 'Положение о структурном подразделении',
            pdf: 'regulation_on_unit.pdf',
        },
    ];

    return (
        <div>
            <TextBlock title="Структура и органы управления образовательной организацией">
                <ol>
                    <li>
                        Высшим органом управления образовательной организации является Акционерное общество
                        &laquo;Технологическая Компания &laquo;Центр&raquo; (ОГРН 1127746385095, ИНН 7715918994).{' '}
                    </li>
                    <li>
                        Учредителями Образовательной организации являются:
                        <ul>
                            <li>Васильев Артем Юрьевич (ИНН 7701351835); +7-495-204-85-00; s8academy@s8.capital</li>
                        </ul>
                        Учредители Образовательной организации являются членами Наблюдательного совета учредителей.
                    </li>
                    <li>
                        Исполнительный орган управления&nbsp;&mdash; Директор:
                        <br />
                        Васильев Артем Юрьевич (495) 204-85-00, адрес: 123112, г. Москва, 1-й Красногвардейский проезд,
                        д.&nbsp;21, с.&nbsp;1; s8academy@s8.capital
                    </li>
                    <li>
                        Коллегиальные органы управления
                        <ul>
                            <li>
                                Коллегиальным органом управления Образовательной организации является Общее собрание
                                работников. Общее собрание работников состоит из&nbsp;всех работников Образовательной
                                организации.
                            </li>
                            <li>
                                Коллегиальным органом управления Образовательной организации является Педагогический
                                совет. Педагогический совет состоит из&nbsp;всех педагогических
                                и&nbsp;научно-педагогических работников Образовательной организации.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Учебные комплексы:
                        <ul>
                            <li>
                                &laquo;ОКО&raquo;
                                <br />
                                (495) 204-85-00, адрес: 123112, г. Москва, 1-й Красногвардейский проезд, д.&nbsp;21,
                                с.&nbsp;1, s8academy@s8.capital
                            </li>
                            <li>
                                &laquo;Авилон&raquo;
                                <br />
                                (495) 204-85-00, адрес: 109316, г. Москва, Волгоградский проспект, д.&nbsp;43, к. З,
                                s8academy@s8.capital
                            </li>
                        </ul>
                    </li>
                </ol>
            </TextBlock>
            <Documents>
                {docs.map((item) => {
                    const name = item.name ? item.name : item.pdf?.split('.pdf')[0];

                    return <Document name={name} download_url={`/pdf/${item.pdf}`} />;
                })}
            </Documents>
            <br />

            <h2>Организационная структура ООО &laquo;С8&nbsp;Академия&raquo;</h2>
            <OrganizationStructure />
        </div>
    );
};
