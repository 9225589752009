import { createGlobalStyle } from 'styled-components';

import ManropeBoldTtf from '../fonts/manrope/Manrope-Bold.ttf';
import ManropeBoldWoff2 from '../fonts/manrope/Manrope-Bold.woff2';
import ManropeExtraLightTtf from '../fonts/manrope/Manrope-ExtraLight.ttf';
import ManropeExtraLightWoff2 from '../fonts/manrope/Manrope-ExtraLight.woff2';
import ManropeLightTtf from '../fonts/manrope/Manrope-Light.ttf';
import ManropeLightWoff2 from '../fonts/manrope/Manrope-Light.woff2';
import ManropeMediumTtf from '../fonts/manrope/Manrope-Medium.ttf';
import ManropeMediumWoff2 from '../fonts/manrope/Manrope-Medium.woff2';
import ManropeRegularTtf from '../fonts/manrope/Manrope-Regular.ttf';
import ManropeRegularWoff2 from '../fonts/manrope/Manrope-Regular.woff2';
import ManropeSemiBoldTtf from '../fonts/manrope/Manrope-SemiBold.ttf';
import ManropeSemiBoldWoff2 from '../fonts/manrope/Manrope-SemiBold.woff2';

export const GlobalStyles = createGlobalStyle`
    :root {
        font-family: Manrope;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body {
        margin: 0;
        min-width: 1636px;
        background-color: var(--bg-color);
        color: var(--text-color);

        --bg-color: #fff;
        --bg-alt-color: #000;
        --text-color: #000;
        --text-alt-color: #c5c5c5; 
        --brand-color-1: #002bff;
        --brand-color-2: #f9760b;
    }

    /* A11y */

    .contrast-theme-white {
        --bg-color: #fff;
        --bg-alt-color: var(--bg-color);
        --text-color: #000;
        --text-alt-color: var(--text-color); 
        --brand-color-1: var(--text-color);
        --brand-color-2: var(--text-color);
    }

    .contrast-theme-black {
        --bg-color: #000;
        --bg-alt-color: #000;
        --text-color: #fff;
        --text-alt-color: var(--text-color); 
        --brand-color-1: var(--text-color);
        --brand-color-2: var(--text-color);
    }

    .contrast-theme-blue {
        --bg-color: #9dd1ff;
        --bg-alt-color: #9dd1ff;
        --text-color: #063462;
        --text-alt-color: var(--text-color); 
        --brand-color-1: var(--text-color);
        --brand-color-2: var(--text-color);
    }

    .contrast-theme-beige {
        --bg-color: #f7f3d6;
        --bg-alt-color: #f7f3d6;
        --text-color: #4d4b43;
        --text-alt-color: var(--text-color); 
        --brand-color-1: var(--text-color);
        --brand-color-2: var(--text-color);
    }

    .contrast-theme-brown {
        --bg-color: #3b2716;
        --bg-alt-color: #3b2716;
        --text-color: #a9e44d;
        --text-alt-color: var(--text-color); 
        --brand-color-1: var(--text-color);
        --brand-color-2: var(--text-color);
    }

    .spacing-1 {
        letter-spacing: 1px;
    }
    
    .spacing-2 {
        letter-spacing: 2px;
    }

    * {
        box-sizing: border-box;
    }
    
    body, html {
        margin: 0;
        padding: 0;
    }
    
    @font-face {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        src: local('Manrope'),
            url(${ManropeRegularWoff2}) format('woff2'),
            url(${ManropeRegularTtf}})  format('ttf');
    }
    @font-face {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 100;
        src: local('Manrope'),
            url(${ManropeExtraLightWoff2}) format('woff2'),
            url(${ManropeExtraLightTtf})  format('ttf');
    }
    @font-face {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 300;
        src: local('Manrope'),
            url(${ManropeLightWoff2}) format('woff2'),
            url(${ManropeLightTtf})  format('ttf');
    }
    @font-face {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        src: local('Manrope'),
            url(${ManropeMediumWoff2}) format('woff2'),
            url(${ManropeMediumTtf})  format('ttf');
    }
    @font-face {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        src: local('Manrope'),
            url(${ManropeSemiBoldWoff2}) format('woff2'),
            url(${ManropeSemiBoldTtf})  format('ttf');
    }
    @font-face {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        src: local('Manrope'),
            url(${ManropeBoldWoff2}) format('woff2'),
            url(${ManropeBoldTtf})  format('ttf');
    }

    h1 {
        font-weight: 800;
        font-size: 2.7em;
        margin-bottom: 22px;
    }

    a {
        color: var(--text-color);
        text-decoration-color: var(--brand-color-1);
    }

    table {
        border-collapse: collapse;
    }

    tr:not(:last-child)  {
        border-bottom: 1px solid var(--text-color);
    }

    td {
        padding-bottom: 8px;
        padding-top: 8px;
        vertical-align: top;
    }
`;
