import React from 'react';

import { TextBlock } from '../../components/widgets/TextBlock';

export const InternationalPage = function InternationalPage() {
    return (
        <TextBlock title="Международное сотрудничество">
            <h4>
                Информация о&nbsp;заключенных и&nbsp;планируемых к&nbsp;заключению договорах с&nbsp;иностранными&nbsp;и
                (или) международными организациями по&nbsp;вопросам образования и&nbsp;науки (при наличии)
            </h4>
            <p>
                Международная деятельность не&nbsp;является приоритетным направлением развития и&nbsp;модернизации
                деятельности АО&nbsp;&laquo;С8&nbsp;Академия&raquo;, но&nbsp;в&nbsp;перспективе рассматривается как один
                из&nbsp;стратегических путей получение дополнительных возможностей его ускоренного развития, расширения
                его образовательного пространства, развития науки, социокультурного взаимодействия с&nbsp;международными
                образовательными учреждениями и&nbsp;организациями.
            </p>
            <p>
                ООО &laquo;С8&nbsp;Академия&raquo; не&nbsp;планирует в&nbsp;ближайшее время заключать договора
                с&nbsp;иностранными&nbsp;и (или) международными организациями по&nbsp;вопросам образования и&nbsp;науки.
            </p>
            <h4>Информация о&nbsp;международной аккредитации образовательных программ</h4>
            <p>
                У&nbsp;образовательной организации нет образовательных программ, прошедших международную аккредитацию.
            </p>
            <p>ООО &laquo;С8&nbsp;Академия&raquo; не&nbsp;является аккредитованным представителем организаций.</p>
        </TextBlock>
    );
};
