import React from 'react';

import { TextBlock } from '../../components/widgets/TextBlock';

export const AccessiblePage = function AccessiblePage() {
    return (
        <TextBlock title="Доступная среда">
            <p>
                Подраздел &laquo;Доступная среда&raquo; содержат информацию о&nbsp;специальных условиях для обучения
                инвалидов и&nbsp;лиц с&nbsp;ограниченными возможностями здоровья, в&nbsp;том числе:
            </p>
            <h4>
                Об&nbsp;электронных образовательных ресурсах, к&nbsp;которым обеспечивается доступ инвалидов и&nbsp;лиц
                с&nbsp;ограниченными возможностями здоровья
            </h4>
            <ul>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href="http://elibrary.ru">
                        Электронная библиотека alpina
                    </a>
                </li>
            </ul>
            <h4>
                О&nbsp;доступе к&nbsp;информационным системам и&nbsp;информационно-телекоммуникационным сетям,
                приспособленным для использования инвалидами и&nbsp;лицами с&nbsp;ограниченными возможностями здоровья
            </h4>
            <ul>
                <li>
                    Высокоскоростная корпоративная вычислительная сеть, обеспечивает доступ к&nbsp;информационным
                    системам и&nbsp;информационно-телекоммуникационным сетям обучающихся, в&nbsp;том числе для
                    использования инвалидам и&nbsp;лицам с&nbsp;ограниченными возможностями здоровья, бесплатно
                    в&nbsp;период действия договора об&nbsp;оказании платных образовательных услуг.
                </li>
                <li>
                    В&nbsp;Образовательной организации возможно подключение к&nbsp;беспроводной сети &laquo;wi-fi&raquo;
                    для доступа к&nbsp;сети &laquo;Интернет&raquo;. Доступ к&nbsp;беспроводной сети &laquo;wi-fi&raquo;
                    осуществляется с&nbsp;использованием логина и&nbsp;пароля.
                </li>
            </ul>
            <h4>Об&nbsp;обеспечении беспрепятственного доступа в&nbsp;здания образовательной организации</h4>
            <ul>
                <li>Возможность беспрепятственного входа в&nbsp;здание и&nbsp;выхода из&nbsp;него;</li>
                <li>
                    Возможность посадки в&nbsp;транспортное средство и&nbsp;высадки из&nbsp;него перед входом в&nbsp;ООО
                    &laquo;С8&nbsp;Академия&raquo; в&nbsp;том числе с&nbsp;использованием кресла-коляски и, при
                    необходимости, с&nbsp;помощью работников АО&nbsp;&laquo;С8&nbsp;Академия&raquo;.
                </li>
                <li>
                    Содействие инвалиду при входе в&nbsp;объект и&nbsp;выходе из&nbsp;него, информирование инвалида
                    о&nbsp;доступных маршрутах общественного транспорта.
                </li>
            </ul>
            <h4>
                Условия питания обучающихся, в&nbsp;том числе инвалидов и&nbsp;лиц с&nbsp;ограниченными возможностями
                здоровья:
            </h4>
            <ul>
                <li>Рядом со&nbsp;зданием Учреждения находятся организации общественного питания.</li>
                <li>Питьевой режим в&nbsp;Учреждении обеспечивается кулерами с&nbsp;холодной и&nbsp;горячей водой.</li>
                <li>Для хранения принесённых с&nbsp;собой продуктов предусмотрены холодильники.</li>
            </ul>
            <h4>Охрана здоровья обучающихся</h4>
            <ul>
                <li>
                    соблюдение санитарно-эпидемиологических требований, в&nbsp;том числе по&nbsp;предупреждению
                    распространения COVID-19;
                </li>
                <li>имеется аптечка медицинской помощи;</li>
                <li>проводится регулярная уборка и&nbsp;дезинфекция помещений;</li>
                <li>
                    обеспечивается профилактика несчастных случаев с&nbsp;обучающимися во&nbsp;время пребывания
                    в&nbsp;организации;
                </li>
                <li>ведется пропаганда здорового образа жизни&nbsp;&mdash; запрет курения.</li>
            </ul>
            <h4>
                Информация о&nbsp;специальных условиях для обучения инвалидов и&nbsp;лиц с&nbsp;ограниченными
                возможностями здоровья:
            </h4>
            <p>Имеется пандус при входе в&nbsp;здание Комплекса</p>
            <h4>
                Информация об&nbsp;объектах для проведения практических занятий, приспособленных для использования
                инвалидами и&nbsp;лицами с&nbsp;ограниченными возможностями здоровья:
            </h4>
            <p>Отсутствует</p>
            <h4>
                Информация о&nbsp;библиотеке(ах), приспособленных для использования инвалидами и&nbsp;лицами
                с&nbsp;ограниченными возможностями здоровья:
            </h4>
            <p>Отсутствует</p>
            <h4>
                Информация об&nbsp;объектах спорта, приспособленных для использования инвалидами и&nbsp;лицами
                с&nbsp;ограниченными возможностями здоровья:
            </h4>
            <p>Отсутствует</p>
            <h4>
                Информация о&nbsp;средствах обучения и&nbsp;воспитания, приспособленных для использования инвалидами
                и&nbsp;лицами с&nbsp;ограниченными возможностями здоровья:
            </h4>
            <p>
                Большинство электронных ресурсов ООО &laquo;С8&nbsp;Академия&raquo; доступно для лиц
                с&nbsp;ограниченными возможностями при условии использования современных инструментальных средств
                увеличения, озвучивания и&nbsp;оптимизации просмотра и&nbsp;взаимодействия, предустановленных
                во&nbsp;всех современных компьютерных операционных системах. Ряд из&nbsp;них имеют специальные
                &laquo;версии для слабовидящих&raquo;. Электронная образовательная среда ООО
                &laquo;С8&nbsp;Академия&raquo; построена на&nbsp;основе модульной объектно-ориентированной цифровой
                платформы, оптимизированной для работы с&nbsp;лицами с&nbsp;ограниченными возможностями по&nbsp;зрению,
                слуху и&nbsp;моторике.
            </p>
            <h4>
                Информация о&nbsp;наличии специальных технических средств обучения коллективного и&nbsp;индивидуального
                пользования:
            </h4>
            <p>Отсутствует</p>
            <h4>Информация о&nbsp;наличии условий для беспрепятственного доступа в&nbsp;общежитие:</h4>
            <p>Общежитие отсутствует</p>
            <h4>Информация о&nbsp;наличии условий для беспрепятственного доступа в&nbsp;интернат:</h4>
            <p>Интернат отсутствует</p>
        </TextBlock>
    );
};
