import React from 'react';

import { Document, Documents } from '../../components/widgets/Documents';
import { TextBlock } from '../../components/widgets/TextBlock';

export const DocumentsPage = function DocumentsPage() {
    const docs = [
        { pdf: 'Устав.pdf' },
        { pdf: 'Правила внутреннего распорядка обучающихся.pdf' },
        {
            name: 'План санитарно-противоэпидемических профилактических мероприятий',
            pdf: 'the_plan.pdf',
        },
        {
            name: 'Положение о структурном подразделении',
            pdf: 'regulation_on_unit.pdf',
        },
        { pdf: 'Выписка по лицензии.pdf' },
        { pdf: 'Карточка компании.pdf' },
        { pdf: 'Образцы документов.pdf' },
        { pdf: 'Отчет о результатах самообследования по итогам за 2022 год.pdf' },
        { pdf: 'Положение о комиссии по урегулированию споров.pdf' },
        { pdf: 'Положение о нормах профессиональной этики педагогических работников.pdf' },
        { pdf: 'Положение о порядке бесплатного пользования методическими материалами.pdf' },
        { pdf: 'Положение о порядке и условиях оказания платных образовательных услуг.pdf' },
        { pdf: 'Положение о порядке обучения по индивидуальному учебному плану.pdf' },
        { pdf: 'Положение о порядке организации и проведения аттестации обучающихся.pdf' },
        { pdf: 'Положение о порядке оформления, выдачи и хранения документов.pdf' },
        { pdf: 'Положение о правилах приема обучающихся.pdf' },
        { pdf: 'Положение о проведении самообследования.pdf' },
        { pdf: 'Положение о формах, периодичности и порядке текущего контроля успеваемости.pdf' },
        { pdf: 'Положение о языке (языках) образования.pdf' },
        { pdf: 'Положение об электронном обучении, использовании дистанционных образовательных технологий.pdf' },
        { pdf: 'Порядок оформления отношений между образ.организацией и обучающимися.pdf' },
        { pdf: 'Порядок перевода, отчисления и восстановления обучающихся.pdf' },
        { pdf: 'Свидетельство о постановке на учет в налоговом органе.pdf' },
    ];

    return (
        <div>
            <TextBlock title="Документы">
                Локальные нормативные акты образовательной организации по основным вопросам организации и осуществления
                образовательной деятельности
            </TextBlock>
            <Documents>
                {docs.map((item) => {
                    const name = item.name ? item.name : item.pdf?.split('.pdf')[0];

                    return <Document name={name} download_url={`/pdf/${item.pdf}`} />;
                })}
            </Documents>
        </div>
    );
};
