import styled from 'styled-components';

export const DefaultLayoutStyle = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100%;
`;

export const ContentWrapper = styled.div`
    display: flex;
    padding: 74px 104px;
    flex-grow: 1;
`;
