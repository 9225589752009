import styled from 'styled-components';

export const Status = styled.h4`
    font-size: 4rem;
    margin-bottom: 16px;
    margin-top: 0;
`;

export const Text = styled.p`
    font-size: 2rem;
`;
