import React from 'react';

import { A11yPanel, setInitialA11y } from '../A11yPanel';
import { ReactComponent as EyeLogo } from './assets/eye.svg';
import { ReactComponent as Logo } from './assets/logo_S8_Akademia.svg';
import { A11yPanelBtn, HeaderStyle } from './style';

export function Header() {
    const [isA11yPanelOpen, toggleA11yPanel] = React.useState(false);

    const onA11yPanelOpen = () => {
        toggleA11yPanel(true);
        setInitialA11y();
    };

    return (
        <>
            {isA11yPanelOpen && (
                <A11yPanel
                    onClose={() => {
                        toggleA11yPanel(false);
                    }}
                />
            )}
            <HeaderStyle>
                <div className="logo">
                    <Logo role="img" />
                </div>
                <A11yPanelBtn type="button" onClick={() => onA11yPanelOpen()}>
                    <EyeLogo role="img" />
                    <span>Версия для слабовидящих</span>
                </A11yPanelBtn>
            </HeaderStyle>
        </>
    );
}
