export enum ROUTES {
    MAIN = '/',
    INFO = 'info',
    GEN_INFO = 'general_information',
    STRUCTURE = 'structure_and_governance',
    DOCUMENTS = 'documents',
    EDUCATION = 'education',
    STANDARTS = 'educational_standards',
    STAFF = 'staff',
    MAT_SUPPORT = 'material_technical_support',
    SCHOLARSHIP = 'scholarships_support',
    SERVICES = 'paid_services',
    FINANCIAL = 'financial_activities',
    VACANT = 'vacant_student_places',
    ACCESSIBLE = 'accessible_environment',
    INTERNATIONAL = 'international_collaboration',

    PROGRAM_PAGE = '/courses/:id',
}
