import React from 'react';
import { Link } from 'react-router-dom';

import { useCourses } from '../../../../api';

export function ProgramsContent() {
    const { data: courses, isLoading } = useCourses();

    if (isLoading) return <>Загрузка...</>;

    return (
        <div>
            {courses?.map((course) => (
                <li key={course.id} style={{ marginBottom: '4px' }}>
                    <Link to={`/courses/${course.id}`}>{course.title}</Link>
                </li>
            ))}
        </div>
    );
}

export function Programs() {
    return (
        <ul>
            <ProgramsContent />
        </ul>
    );
}
