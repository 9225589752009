import React from 'react';

import { TextBlock } from '../../components/widgets/TextBlock';

export const InfoPage: React.FC = function InfoPage() {
    return (
        <TextBlock title="Основные сведения:">
            <h2>Реквизиты ООО &laquo;С8&nbsp;Академия&raquo;</h2>
            <p>
                <strong>Вниманию клиентов!</strong> Уточняйте реквизиты по&nbsp;договору у&nbsp;Вашего менеджера!
            </p>
            <table>
                <tr>
                    <td>Полное наименование фирмы (в&nbsp;соответствии с&nbsp;учредительными документами)</td>
                    <td>Общество с&nbsp;ограниченной ответственностью &laquo;С8&nbsp;Академия&raquo;</td>
                </tr>
                <tr>
                    <td>Сокращенное название</td>
                    <td>ООО &laquo;С8&nbsp;Академия&raquo;</td>
                </tr>
                <tr>
                    <td>Дата создания</td>
                    <td>11&nbsp;ноября 2021 года</td>
                </tr>
                <tr>
                    <td>Лицензия</td>
                    <td>
                        Лицензия на&nbsp;осуществление образовательной деятельности &#8470;&nbsp;041780 (приказ
                        &#8470;&nbsp;1057Л от&nbsp;11.11.2021), выдана 19.11.2021&nbsp;г. Департаментом образования
                        и&nbsp;науки города Москвы, срок действия&nbsp;&mdash; бессрочно
                    </td>
                </tr>
                <tr>
                    <td>Юридический адрес (в&nbsp;соответствии с&nbsp;учредительными документами)</td>
                    <td>
                        111250, г. Москва, проезд завода Серп и&nbsp;Молот, дом&nbsp;6, подъезд&nbsp;1, этаж&nbsp;3,
                        комн.&nbsp;8
                    </td>
                </tr>
                <tr>
                    <td>Фактический полный почтовый адрес</td>
                    <td>123112, г. Москва, 1-й Красногвардейский проезд, д.&nbsp;21, с.&nbsp;1</td>
                </tr>
                <tr>
                    <td>Контактный телефон</td>
                    <td>(495) 204-85-00</td>
                </tr>
            </table>
            <p>
                Высшим органом управления образовательной организации является Акционерное общество
                &laquo;Технологическая Компания &laquo;Центр&raquo; (ОГРН 1127746385095, ИНН 7715918994).
            </p>
            <p>
                Учредителями Образовательной организации являются: Васильев Артем Юрьевич; +7-495-204-85-00;
                s8academy@s8.capital
            </p>
            <p>Учредители Образовательной организации являются членами Наблюдательного совета учредителей.</p>
            <p>Филиалы и представительства организации отсутствуют.</p>

            <h2>Исполнительный орган управления – Директор</h2>
            <p>
                Васильев Артем Юрьевич (495) 204-85-00, адрес: 111250, г. Москва, проезд Завода Серп и&nbsp;Молот,
                дом&nbsp;6, подъезд&nbsp;2, этаж&nbsp;4, комн.&nbsp;18
            </p>

            <br />

            <h2>Учебные комплексы</h2>
            <h3>Координаты комплекса &laquo;ОКО&raquo;</h3>
            <p>
                <b>м. Деловой центр</b>
            </p>
            <p>
                <b>Адрес:</b> ЗАО, 1-й Красногвардейский проезд, д.&nbsp;21, с.&nbsp;1
            </p>
            <p>
                <b>Телефон:</b> (495) 204-85-00
            </p>
            <p>
                <b>Email:</b> s8academy@s8.capital
            </p>
            <p>
                <b>Режим и график работы:</b>
                <br />
                <b>Понедельник&nbsp;&mdash; пятница:</b> с&nbsp;10:00 до&nbsp;19.00
                <br />
                <b>Суббота&nbsp;&mdash; воскресенье:</b> выходной
            </p>
            <h3>Координаты комплекса &laquo;Авилон&raquo;</h3>
            <p>
                <b>м. Текстильщики</b>
            </p>
            <p>
                <b>Адрес:</b> ВАО, Волгоградский проспект, д.&nbsp;43, к. З
            </p>
            <p>
                <b>Телефон:</b> (495) 204-85-00
            </p>
            <p>
                <b>Email:</b> s8academy@s8.capital
            </p>
            <p>
                <b>Режим и график работы:</b>
                <br />
                <b>Понедельник&nbsp;&mdash; пятница:</b> с&nbsp;10:00 до&nbsp;19.00
                <br />
                <b>Суббота&nbsp;&mdash; воскресенье:</b> выходной
            </p>
        </TextBlock>
    );
};
