import React from 'react';

import { TextBlock } from '../../components/widgets/TextBlock';

export const ScholarshipPage = function ScholarshipPage() {
    return (
        <TextBlock title="Стипендии и иные виды материальной поддержки">
            <h4>Сведения о&nbsp;наличии и&nbsp;условиях предоставления обучающимся стипендий</h4>
            <p>ООО &laquo;С8&nbsp;Академия&raquo; не&nbsp;предоставляет обучающимся стипендий</p>
            <h4>Сведения о&nbsp;наличии общежития, интерната</h4>
            <p>В&nbsp;ООО &laquo;С8&nbsp;Академия&raquo; отсутствуют общежития</p>
            <h4>Сведения о&nbsp;количестве жилых помещений в&nbsp;общежитии, интернате для иногородних обучающихся</h4>
            <p>ООО &laquo;С8&nbsp;Академия&raquo; не&nbsp;предоставляет иногородним обучающимся жилых помещений</p>
            <h4>
                Сведения о&nbsp;формировании платы за&nbsp;проживание в&nbsp;общежитии и&nbsp;иных видов материальной
                поддержки обучающихся
            </h4>
            <p>В&nbsp;ООО &laquo;С8&nbsp;Академия&raquo; отсутствуют общежития</p>
            <h4>Информация об&nbsp;иных видах материальной поддержки обучающихся</h4>
            <p>ООО &laquo;С8&nbsp;Академия&raquo; не&nbsp;предоставляет обучающимся стипендий</p>
            <h4>Сведения о&nbsp;продолжении образования (трудоустройстве) выпускников</h4>
            <p>Трудоустройство выпускников в&nbsp;ООО &laquo;С8&nbsp;Академия&raquo; не&nbsp;предусмотрено</p>
            <h4>Информация о&nbsp;мерах социальной поддержки</h4>
            <p>Обучающимся предоставляется возможность оплаты обучения в&nbsp;рассрочку (частями).</p>
        </TextBlock>
    );
};
