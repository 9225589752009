import styled from 'styled-components';

export const HeaderStyle = styled.div`
    padding-left: 106px;
    padding-right: 100px;
    padding-top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    overflow: visible;

    .logo {
        margin-right: 92px;
        width: 304px;
        height: 38px;

        & svg {
            width: 100%;
            height: 100%;
        }
    }

    nav {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 1.2em;

        & > a {
            text-decoration: none;
            color: var(--text-color);

            &:visited {
                color: var(--text-color);
            }
        }
    }
`;

export const A11yPanelBtn = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--text-color);
    font-weight: 700;
    font-size: 1rem;

    & > svg {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }
`;
