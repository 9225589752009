import styled from 'styled-components';

export const DocumentsContainer = styled.div`
    margin-top: 40px;

    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 24px;
`;

export const DocumentStyle = styled.div`
    width: 100%;
    cursor: pointer;

    font-weight: 500;
    font-size: 1.2rem;

    a {
        display: flex;
        align-items: center;
        color: var(--text-color);
        text-decoration: none;

        &:visited {
            color: var(--text-color);
        }
    }

    img {
        pointer-events: none;
        height: 60px;
    }

    p {
        margin: 0;
        margin-left: 36px;
    }
`;
