import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-content: center;
    padding: 10px;
    border-bottom: 2px solid var(--text-color);
`;

export const Panel = styled.div`
    margin: 0 auto;
    display: flex;
`;

export const SubPanel = styled.div`
    display: flex;
    align-items: center;
    margin-right: 32px;

    & > span {
        font-size: 20px;
        margin-right: 16px;
    }

    & > button:not(:last-child) {
        margin-right: 8px;
    }
`;

export const FontSizeBtn = styled.button<{ $active?: boolean }>`
    width: 36px;
    height: 36px;
    background: var(--bg-color);
    color: var(--text-color);
    border-radius: 8px;
    border: 2px solid;
    cursor: pointer;

    border-color: ${(props) => (props.$active ? 'var(--text-color)' : 'transparent')};

    &:hover {
        border-color: var(--text-color);
    }

    &:nth-child(2) {
        font-size: 16px;
    }

    &:nth-child(3) {
        font-size: 20px;
    }

    &:nth-child(4) {
        font-size: 24px;
    }
`;

export const SpacingBtn = styled.button<{ $active?: boolean }>`
    width: 80px;
    height: 36px;
    background: var(--bg-color);
    color: var(--text-color);
    border-radius: 8px;
    border: 2px solid;
    cursor: pointer;
    font-size: 24px;

    border-color: ${(props) => (props.$active ? 'var(--text-color)' : 'transparent')};

    &:hover {
        border-color: var(--text-color);
    }

    &:nth-child(2) {
        letter-spacing: 0px;
    }

    &:nth-child(3) {
        letter-spacing: 2px;
    }

    &:nth-child(4) {
        letter-spacing: 4px;
    }
`;

export const ThemeBtn = styled.button<{ $active?: boolean }>`
    width: 36px;
    height: 36px;
    background: var(--bg-color);
    color: var(--text-color);
    border-radius: 8px;
    border: 3px solid;
    cursor: pointer;
    font-size: 24px;

    border-color: ${(props) => (props.$active ? 'var(--text-color)' : 'transparent')};

    &:hover {
        border-color: var(--text-color);
    }
`;

export const CloseBtn = styled.button`
    background: var(--bg-color);
    color: var(--text-color);
    border-radius: 8px;
    border: 2px solid;
    cursor: pointer;
    font-size: 20px;
    border-color: var(--text-color);
`;
