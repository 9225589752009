import React from 'react';

import { Document, Documents } from '../../components/widgets/Documents';
import { TextBlock } from '../../components/widgets/TextBlock';

export const StandartsPage = function StandartsPage() {
    // нет документов
    // Положение о порядке разработки, утверждения и обновления дополнительных и дополнительных профессиональных программ
    const docs = [
        {
            name: 'Приказ Минобрнауки России от 01.07.2013 N 499 (ред. от 15.11.2013) "Об утверждении Порядка организации и осуществления образовательной деятельности по дополнительным профессиональным программам" (Зарегистрировано в Минюсте России 20.08.2013 N 29444)',
            pdf: 'prikaz_poryadok.pdf',
        },
    ];

    return (
        <div>
            <TextBlock title="Образовательные стандарты">
                <p>
                    В&nbsp;соответствии с&nbsp;Федеральным законом от&nbsp;29&nbsp;декабря 2012&nbsp;г. N&nbsp;273-ФЗ
                    &laquo;Об&nbsp;образовании в&nbsp;Российской Федерации&raquo; (далее&nbsp;&mdash; Федеральный закон
                    N&nbsp;273-ФЗ) содержание дополнительных профессиональных программ, разрабатываемых
                    АО&nbsp;&laquo;С8&nbsp;Академия&raquo;, учитывает профессиональные стандарты, квалификационные
                    требования, указанные в&nbsp;квалификационных справочниках по&nbsp;соответствующим должностям,
                    профессиям и&nbsp;специальностям (часть 9&nbsp;статьи 76&nbsp;Федерального закона N&nbsp;273-ФЗ).
                </p>
                <p>
                    <a href="https://zakon-ob-obrazovanii.ru/">
                        Федеральный закон &laquo;Об&nbsp;образовании в&nbsp;Российской Федерации&raquo; N&nbsp;273-ФЗ
                        от&nbsp;29&nbsp;декабря 2012 года
                    </a>
                </p>
            </TextBlock>

            <Documents>
                {docs.map((item) => {
                    const name = item.name ? item.name : item.pdf?.split('.pdf')[0];

                    return <Document name={name} download_url={`/pdf/${item.pdf}`} />;
                })}
            </Documents>
        </div>
    );
};
