import React from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '../../components/widgets/Footer';
import { Header } from '../../components/widgets/Header';
import { Menu } from '../../components/widgets/Menu';
import { ContentWrapper, DefaultLayoutStyle } from './style';

export const DefaultLayout: React.FC = function DefaultLayout() {
    return (
        <DefaultLayoutStyle>
            <Header />
            <ContentWrapper>
                <Menu />
                <Outlet />
            </ContentWrapper>
            <Footer />
        </DefaultLayoutStyle>
    );
};
