import React from 'react';

import { Status, Text } from './style';

export function NotFoundPage() {
    return (
        <div>
            <Status>404</Status>
            <Text> страница не найдена.</Text>
        </div>
    );
}
