import React from 'react';

import { TextBlock } from '../../components/widgets/TextBlock';

export const VacantPage = function VacantPage() {
    return (
        <TextBlock title="Вакантные места для приема (перевода) обучающихся">
            <p>Набор в&nbsp;группы и&nbsp;обучение ведется в&nbsp;течение всего календарного года.</p>
            <p>
                Вакантные места по&nbsp;договорам об&nbsp;образовании за&nbsp;счет средств физических&nbsp;и (или)
                юридических лиц по&nbsp;реализуемым образовательным программам не&nbsp;ограничены.
            </p>
            <p>
                Бюджетное финансирование не&nbsp;производится. Финансируемых за&nbsp;счет бюджетных ассигнований
                федерального бюджета, бюджетов субъектов&nbsp;РФ и&nbsp;местных бюджетов мест для приема и&nbsp;перевода
                не&nbsp;имеется.
            </p>
            <p>
                Информацию о&nbsp;начале обучения и&nbsp;наличии мест в&nbsp;группах необходимо уточнять
                по&nbsp;телефону&nbsp;+7 (495) 204-85-00 или написав письмо по адресу s8academy@s8.capital
            </p>
        </TextBlock>
    );
};
