import React from 'react';

import { OrganizationStructureStyle, StructureItem } from './style';

interface IItem {
    level?: number;
    style?: {
        background: string;
        color: string;
        border?: string;
    };
    children: string;
}

function Item(props: IItem) {
    return (
        <StructureItem
            level={props.level || 0}
            style={{
                ...props.style,
            }}
        >
            {props.children}
        </StructureItem>
    );
}

Item.defaultProps = {
    level: undefined,
    style: undefined,
};

export function OrganizationStructure() {
    return (
        <OrganizationStructureStyle>
            <Item
                style={{
                    background: 'var(--brand-color-2)',
                    color: 'var(--bg-color)',
                    border: 'none',
                }}
            >
                S8 Capital
            </Item>
            {['S8 Academy', 'Генеральный директор', 'Корпоративный Университет'].map((p) => (
                <Item
                    key={p}
                    style={{
                        background: 'var(--brand-color-1)',
                        color: 'var(--bg-color)',
                        border: 'none',
                    }}
                >
                    {p}
                </Item>
            ))}
            <div className="sub_level">
                {[
                    'Директор корпоративного университета',
                    'Руководитель направления «ИТ-направление»',
                    'Руководитель направления «Корпоративное направление»',
                    'Руководитель направления «Партнерское направление»',
                    'Ведущий методолог',
                ].map((position) => (
                    <Item key={position} level={1}>
                        {position}
                    </Item>
                ))}
            </div>
        </OrganizationStructureStyle>
    );
}
