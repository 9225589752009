import React from 'react';

import { Document, Documents } from '../../components/widgets/Documents';
import { TextBlock } from '../../components/widgets/TextBlock';
import { Programs } from './widgets/Programs';

export const EducationPage = function EducationPage() {
    // нет документов
    // Календарный учебный график на 2022 год
    const docs: any[] = [{ pdf: 'Положение о языке (языках) образования.pdf' }];

    return (
        <TextBlock title="Образование">
            <p>
                ООО &laquo;С8&nbsp;Академия&raquo; в&nbsp;соответствии с&nbsp;лицензией на&nbsp;осуществление
                образовательной деятельности &#8470;&nbsp;039441 (бланк серия СР77Л01 &#8470;&nbsp;0010312,
                регистрационный номер лицензии Л035-01298-77/00182700), выданной 20.06.2018&nbsp;г. Департаментом
                образования города Москвы (бессрочно), оказывает услуги дополнительного профессионального образования.
                Обучение проводится по&nbsp;очным, очно-заочным, заочным формам с&nbsp;применением электронного обучения
                и&nbsp;дистанционных технологий, форма обучения указывается в&nbsp;соответствующей программе обучения.
            </p>
            <p>
                Срок обучения по&nbsp;дополнительны профессиональным программам устанавливается учреждением
                самостоятельно и&nbsp;не&nbsp;может быть меньше 16&nbsp;академических часов для программ повышения
                квалификации
            </p>
            <p>
                В&nbsp;соответствии с&nbsp;действующим законодательством об&nbsp;образовании ООО
                &laquo;С8&nbsp;Академия&raquo; осуществляет образовательную деятельность на&nbsp;русском языке.
            </p>
            <p>
                ООО &laquo;С8&nbsp;Академия&raquo; разработаны учебные планы, которые содержат перечень тем, дисциплин,
                последовательность и&nbsp;продолжительность их&nbsp;изучения и&nbsp;определяют форму итоговой аттестации
                обучающихся. Расписание учебных занятий для слушателей соответствуют тематическим планам и&nbsp;учебным
                программам. ООО &laquo;С8&nbsp;Академия&raquo; принимает локальные нормативные акты по&nbsp;основным
                вопросам организации и&nbsp;осуществления образовательной деятельности (обеспечение образовательного
                процесса), в&nbsp;том числе регламентирующие правила приема обучающихся, режим занятий обучающихся,
                формы, периодичность и&nbsp;порядок текущего контроля успеваемости и&nbsp;промежуточной аттестации
                обучающихся, порядок и&nbsp;основания перевода, отчисления и&nbsp;восстановления обучающихся, порядок
                оформления возникновения, приостановления и&nbsp;прекращения отношений между образовательной
                организацией и&nbsp;обучающимися&nbsp;и (или) родителями (законными представителями) несовершеннолетних
                обучающихся&quot;.
            </p>
            <h2>Образовательные программы повышения квалификации</h2>
            <Programs />
            <p>
                По&nbsp;реализуемым образовательным программам за&nbsp;счет бюджетных ассигнований федерального бюджета,
                бюджетов субъектов Российской Федерации, местных бюджетов обучающихся нет. Количество обучающихся
                по&nbsp;договорам с&nbsp;юридическими и&nbsp;физическими лицами зависит от&nbsp;расписания занятий
                краткосрочных программ повышения квалификации и&nbsp;программ переподготовки. Предоставление обучающимся
                стипендий и&nbsp;общежития не&nbsp;предусмотрено. Трудоустройство выпускников не&nbsp;предусмотрено.
            </p>
            <h2>Численность обучающихся</h2>
            <p>
                Обучающиеся, являющиеся иностранными гражданами, по&nbsp;реализуемым образовательным программам
                за&nbsp;счет бюджетных ассигнований федерального бюджета, за&nbsp;счет бюджетных ассигнований бюджетов
                субъектов Российской Федерации, за&nbsp;счет бюджетных ассигнований местных бюджетов&nbsp;&mdash;
                отсутствуют.
            </p>
            <p>
                Обучающиеся по&nbsp;реализуемым образовательным программам за&nbsp;счет бюджетных ассигнований
                федерального бюджета, за&nbsp;счет бюджетных ассигнований бюджетов субъектов Российской Федерации,
                за&nbsp;счет бюджетных ассигнований местных бюджетов&nbsp;&mdash; отсутствуют.
            </p>
            <p>
                Численность обучающихся (в&nbsp;том числе являющиеся иностранными гражданами) по&nbsp;договорам
                за&nbsp;счет средств физических лиц и&nbsp;юридических лиц: в&nbsp;настоящее время в&nbsp;Центре
                обучается более 100&nbsp;человек.
            </p>
            <p>
                По&nbsp;каждому курсу обучающиеся обеспечиваются доступом к&nbsp;видеолекциям, расположенным
                во&nbsp;внутренней сети организации, а&nbsp;также получают доступ к&nbsp;электронным методическим
                пособиям, если таковые предусмотрены.
            </p>
            <p>Заключенных Договоров с&nbsp;иностранными/международными организациями&nbsp;&mdash; 0&nbsp;шт.</p>

            <Documents>
                {docs.map((item) => {
                    const name = item.name ? item.name : item.pdf?.split('.pdf')[0];

                    return <Document name={name} download_url={`/pdf/${item.pdf}`} />;
                })}
            </Documents>
        </TextBlock>
    );
};
