import React from 'react';

import { TextBlock } from '../../components/widgets/TextBlock';

export const SupportPage = function SupportPage() {
    return (
        <TextBlock title="Материально-техническое обеспечение и оснащенность образовательного процесса">
            <p>
                Места осуществления образовательной деятельности в&nbsp;соответствии с&nbsp;частью 4&nbsp;статьи
                91&nbsp;Федерального закона от&nbsp;29&nbsp;декабря 2012&nbsp;г. N&nbsp;273-ФЗ
                &laquo;Об&nbsp;образовании в&nbsp;Российской Федерации&raquo;:
                <ul>
                    <li>г.Москва, 1-й Красногвардейский проезд, д.&nbsp;21, с.&nbsp;1</li>
                    <li>г.Москва, Волгоградский проспект, д.&nbsp;43, к. З</li>
                </ul>
            </p>
            <h4>Учебные кабинеты ООО &laquo;С8&nbsp;Академия&raquo; имеют следующее оборудование:</h4>
            <ul>
                <li>Проекторы</li>
                <li>Экраны проекционные</li>
                <li>Доски для маркера</li>
                <li>Ноутбуки</li>
                <li>Демонстрационные материалы</li>
            </ul>
            <p>
                Компьютеры оснащены проводным и&nbsp;беспроводным доступом к&nbsp;информационно-телекоммуникационной
                сети Интернет. Скорость подключения до&nbsp;50&nbsp;Мбит.
            </p>
            <table>
                <tr>
                    <td>Сведения о&nbsp;наличии оборудованных учебных кабинетов</td>
                    <td>
                        В&nbsp;учебных аудиториях созданы условия для оказания успешного освоения обучающих программ
                        слушателями. Ведётся разработка учебно-методических пособий и&nbsp;средств интерактивного
                        обучения слушателей, в&nbsp;том числе для инвалидов и&nbsp;лиц с&nbsp;ограниченными
                        возможностями.
                    </td>
                </tr>
                <tr>
                    <td>
                        Сведения о&nbsp;доступе к&nbsp;информационным системам и&nbsp;информационно-телекоммуникационным
                        сетям
                    </td>
                    <td>
                        В&nbsp;образовательной организации в&nbsp;учебных классах организован доступ
                        к&nbsp;информационно-телекоммуникационной сети Интернет.
                    </td>
                </tr>
                <tr>
                    <td>
                        Сведения об&nbsp;электронных образовательных ресурсах, к&nbsp;которым обеспечивается доступ
                        обучающихся
                    </td>
                    <td>
                        Обучающиеся имеют доступ к&nbsp;образовательным ресурсам, размещённым во&nbsp;внутренней сети
                        образовательной организации, а&nbsp;также на&nbsp;закрытых площадках образовательной организации
                        в&nbsp;сети Интернет.
                    </td>
                </tr>
                <tr>
                    <td>Сведения о&nbsp;наличии библиотек, объектов спорта, средств обучения и&nbsp;воспитания</td>
                    <td>
                        Создана библиотека печатных изданий. Обучающимся по&nbsp;всем образовательным программам
                        выдаются литература и&nbsp;методические пособия.
                        <br />
                        В&nbsp;указанных помещениях размещены современные средства обучения&nbsp;&mdash; аудиовизуальные
                        средства обучения&nbsp;&mdash; проекционные системы. Ведётся разработка наглядных средств
                        духовно-нравственного воспитания обучающихся.
                        <br />
                        Для лиц с&nbsp;ограниченными возможностями и&nbsp;инвалидов предусмотрены специальные
                        технические средства обучения и&nbsp;дистанционная форма обучения.
                        <br />
                        Объекты спорта не&nbsp;предусмотрены.
                    </td>
                </tr>
                <tr>
                    <td>Сведения об&nbsp;условиях питания и&nbsp;охраны здоровья обучающихся</td>
                    <td>
                        Для обеспечения условий питания обучающихся предусмотрена оборудованная кухня
                        с&nbsp;холодильником, микроволновой печкой, водонагревателем и&nbsp;приборами. На&nbsp;занятиях
                        преподавателями доводится информация, направленная на&nbsp;формирование здорового образа жизни.
                    </td>
                </tr>
                <tr>
                    <td>
                        Сведения об&nbsp;обеспечении доступа в&nbsp;здания образовательной организации инвалидов
                        и&nbsp;лиц с&nbsp;ограниченными возможностями здоровья
                    </td>
                    <td>Доступно частично всем.</td>
                </tr>
                <tr>
                    <td>Сведения о&nbsp;наличии объектов, для проведения практических занятий</td>
                    <td>Отсутствуют</td>
                </tr>
                <tr>
                    <td>Сведения о&nbsp;наличии средств обучения и&nbsp;воспитания</td>
                    <td>
                        Обучение осуществляется с&nbsp;использованием дистанционных образовательных технологий
                        с&nbsp;использованием электронного образовательного ресурса: iSpring
                    </td>
                </tr>
            </table>
        </TextBlock>
    );
};
