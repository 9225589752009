import React from 'react';

import { Illustration, Text, Title, Wrapper } from './style';

export function MainPage() {
    return (
        <div>
            <Title>Корпоративный университет</Title>
            <Wrapper>
                {' '}
                <Text>
                    <p>
                        S8 Академия поддерживает культуру непрерывного обучения и развития компетенций сотрудников и
                        руководителей S8 Capital, создаёт инновационную среду и выстраивает институт наставничества и
                        кадрового резерва.
                    </p>
                    <p>
                        Корпоративный университет S8 Академия организует и проводит обучающие мероприятия для
                        сотрудников и партнёров холдинга: тренинги, мастер-классы, вебинары, квизы и другие активности.
                    </p>
                </Text>
                <Illustration role="img" aria-label="Иллюстрация" />
            </Wrapper>
        </div>
    );
}
